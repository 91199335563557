const listProfil = [
  { id: 1, label: "Ministre", niveau: "1" },
  { id: 2, label: "Directeur-trice de Cabinet", niveau: "1" },
  { id: 3, label: "Directeur-trice de Cabinet Adjoint", niveau: "1" },
  { id: 4, label: "Conseiller-ère Technique", niveau: "1" },
  { id: 4, label: "Secrétaire Général", niveau: "1" },
  { id: 6, label: "Directeur-trice des Services Vétérinaires", niveau: "1" },
  { id: 5, label: "Directeur-trice des Productions Animales", niveau: "1" },
  { id: 5, label: "Directeur-trice de la transhumance", niveau: "1" },
  { id: 5, label: "Directeur-trice Chargé-e des Statistiques et de la Planification", niveau: "1" },
  { id: 5, label: "Chargé-e de Mission", niveau: "1" },
  { id: 18, label: "Coordonateur-trice OP", niveau: "1" },
  { id: 18, label: "Coordonateur-trice projet", niveau: "1" },
  { id: 18, label: "Sous-Directeur-trice", niveau: "1" },

  { id: 10, label: "Directeur-trice Régional de l'élévage", niveau: "2" },
  { id: 11, label: "Directeur-trice Départemental de l'élévage", niveau: "3" },

  { id: 19, label: "Vétérinaire public", niveau: "4I" },
  { id: 18, label: "Vétérinaire privé", niveau: "4I" },
  { id: 18, label: "Agent Départemental", niveau: "4I" },
  { id: 18, label: "Auxiliaire d'élevage", niveau: "4I" },
  { id: 18, label: "Prestataire", niveau: "4I" },
  { id: 18, label: "Technicien Vétérinaire", niveau: "4I" },
  { id: 18, label: "Para-Vétérinaire", niveau: "4I" },
  { id: 18, label: "Pharmacien Vétérinaire", niveau: "4I" },

  { id: 18, label: "Exploitant", niveau: "5E" },
  { id: 18, label: "Gérant", niveau: "5E" },
];

/* export const listProfilN5 = [{ id: 18, label: "Exploitant" }];
export const listProfilN4R = [{ id: 18, label: "Directeur Exploitation" }];
export const listProfilN4I = [
  { id: 19, label: "Vétérinaire public" },
  { id: 18, label: "Vétérinaire privé" },
  { id: 18, label: "Service Technique Décentralisé" },
  { id: 18, label: "Prestaire" },
];
export const listProfilN3 = [{ id: 11, label: "Directeur Départemental" }];
export const listProfilN2 = [{ id: 10, label: "Directeur Régional" }];
export const listProfilN1 = [
  { id: 1, label: "Ministre" },
  { id: 2, label: "Directeur de Cabinet" },
  { id: 3, label: "Directeur de Cabinet Adjoint" },
  { id: 4, label: "Conseiller Technique" },
  { id: 5, label: "Directeur de la Production Animal" },
  { id: 6, label: "Directeur des Services Vétérinaire" },
]; */
export const listProfilN1 = listProfil.filter((i) => i.niveau === "1");
export const listProfilN2 = listProfil.filter((i) => i.niveau === "2");
export const listProfilN3 = listProfil.filter((i) => i.niveau === "3");
export const listProfilN4I = listProfil.filter((i) => i.niveau === "4I");
export const listProfilN4R = listProfil.filter((i) => i.niveau === "4I");
export const listProfilN5 = listProfil.filter((i) => i.niveau === "5E");

export default listProfil;
