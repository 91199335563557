import userInfos, { isAdminDepartemental, checkRoleExist } from "utils/userInfos";
import useArrayOperation from "hooks/useArrayOperation";
import { MODULE_LIEU } from "constant/moduleInfo";
import { ERROR } from "constant/message";
import { CODE_PAYS } from "constant";
import { ADMIN_REGION_ROLE, RESPONSABLE_FERME } from "constant/role";

const { removeDuplicateItem } = useArrayOperation();
const getLieu = async (getData) => {
  const fetchData = await getData(MODULE_LIEU);
  let lieuClassified = {};
  if (fetchData !== ERROR) {
    const dataLieu = fetchData.filter(
      (i) =>
        i.codePays === CODE_PAYS &&
        i.region !== null &&
        i.codeRegion !== null &&
        i.departement !== null &&
        i.codeDepart !== null &&
        i.commune !== null &&
        i.codeCommune !== null
    );
    const canAddbyAdminDepartement = isAdminDepartemental();
    const canAddbyAdminRegion = checkRoleExist(ADMIN_REGION_ROLE);
    const isResponsableFerme = checkRoleExist(RESPONSABLE_FERME);
    let relatedLieu = dataLieu;
    const userData = userInfos();

    if (isResponsableFerme) {
      relatedLieu = dataLieu.filter(
        (i) =>
          i.codeRegion === userData.lieu.codeRegion &&
          i.codeDepart === userData.lieu.codeDepart &&
          i.codeCommune === userData.lieu.codeCommune
      );
      // console.log("sss // in ", relatedLieu);
      // console.log("dataLieu //", dataLieu.length);
    }
    if (canAddbyAdminDepartement) {
      // console.log("is responsable //", isResponsableFerme);
      relatedLieu = dataLieu.filter(
        (i) =>
          i.codeRegion === userData.lieu.codeRegion && i.codeDepart === userData.lieu.codeDepart
      );
      // console.log("sss // in ", relatedLieu);
      // console.log("dataLieu //", dataLieu.length);
    }

    if (canAddbyAdminRegion) {
      relatedLieu = dataLieu.filter((i) => i.codeRegion === userData.lieu.codeRegion);
    }
    // console.log("sss // out ", relatedLieu.length);
    const listRegion = removeDuplicateItem(relatedLieu, "region");
    const listDepartement = removeDuplicateItem(relatedLieu, "departement");
    const listCommune = removeDuplicateItem(relatedLieu, "commune");
    lieuClassified = {
      listRegion,
      listDepartement,
      listCommune,
      relatedLieu,
    };
  }
  return lieuClassified;
};
export default getLieu;
