import React, { useEffect, useState } from "react";
import { TextField, Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import dateFormat from "utils/dateFormat";
import { checkRoleExist } from "utils/userInfos";
import { RESPONSABLE_FERME, SUPER_ADMIN_ROLE } from "constant/role";
import AutoCompleteComponent from "components/AutoCompleteComponent";
import statutInseminationList from "constant/statutInseminationList";
import MDButton from "components/MDButton";
import { updateInsemination } from "services/inseminationService";
import { PATH_INSEMINATION } from "constant/moduleInfo";
import { ERROR } from "constant/message";
import { useNavigate } from "react-router-dom";

const InseminationInfo = ({ info, putData, handleLoad }) => {
  const navigate = useNavigate();
  const [insemination, setInsemination] = useState(info);
  const [canUpdate, setCanUpdate] = useState(false);
  let dateInjection = "";
  if (info?.dateInjection === null) {
    dateInjection = "Non définie";
  } else {
    dateInjection = dateFormat(new Date(info?.dateInjection));
  }
  let dateRetraitSpiral = "";
  if (info?.dateRetraitSpiral === null) {
    dateRetraitSpiral = "Non définie";
  } else {
    dateRetraitSpiral = dateFormat(new Date(info?.dateRetraitSpiral));
  }
  let postSpiral = "";
  if (info?.postSpiral === null) {
    postSpiral = "Non définie";
  } else {
    postSpiral = dateFormat(new Date(info?.postSpiral));
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("aaaa", name, value);
    let updatedInsemination;
    if (name === "statutInsemination") {
      const { newValue } = value;
      updatedInsemination = {
        ...insemination,
        status: newValue.name,
        statutInsemination: newValue,
      };
    }
    setInsemination(updatedInsemination);
  };
  const handleUpdate = async () => {
    console.log("ssss", insemination);
    const result = await updateInsemination(putData, [insemination]);
    if (result !== ERROR) {
      const loadData = {
        isAlert: true,
        message: "Opération effectuée avec succès",
        isLoading: false,
        alertSeverity: "success",
      };
      handleLoad(loadData);
      setTimeout(() => {
        navigate(PATH_INSEMINATION);
        // navigate(PATH_DEMANDE_IDENTIFICATION);
      }, 200);
    }
  };
  useEffect(() => {
    const isResponsableFerme = checkRoleExist(RESPONSABLE_FERME);
    const isSuperAdmin = checkRoleExist(SUPER_ADMIN_ROLE);
    if (isResponsableFerme || isSuperAdmin) {
      setCanUpdate(true);
    }
  }, []);
  // console.log("ssss", info?.statutInsemination);
  //  console.log("ssss", info);
  return (
    <Box
      sx={{ padding: 2, border: "1px solid #ccc", borderRadius: 2, margin: 2 }}
      style={{ backgroundColor: "white" }}
    >
      <Box textAlign="center">
        <Typography variant="h6">Insémination</Typography>
      </Box>
      <TextField
        label="Date Injection"
        name="dateInjection"
        value={dateInjection ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Date Pose Spiral"
        name="postSpiral"
        value={postSpiral ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Date Retrait Spiral"
        name="dateRetraitSpiral"
        value={dateRetraitSpiral ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Exploitation"
        name="ferme"
        value={info?.ferme?.nom ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Observation"
        name="observation"
        value={info?.observation ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <AutoCompleteComponent
        options={statutInseminationList}
        name="statutInsemination"
        label="statut"
        value={insemination?.statutInsemination ?? ""}
        onChange={handleInputChange}
        isResultObject={false}
        isDisabled={!canUpdate}
      />
      {canUpdate && (
        <Box display="flex" alignItems="center" justifyContent="center">
          <MDButton type="submit" variant="gradient" color="info" onClick={handleUpdate}>
            Modifier
          </MDButton>
        </Box>
      )}
    </Box>
  );
};

export default InseminationInfo;
InseminationInfo.propTypes = {
  info: PropTypes.object.isRequired,
  putData: PropTypes.func.isRequired,
  handleLoad: PropTypes.func.isRequired,
};
