import { useState, useEffect } from "react";

// react-router components
import { /* useLocation, */ useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
// import MDBox from "components/MDBox";
// import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
// import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
// import MDTypography from "components/MDTypography";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  // navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  // setOpenConfigurator,
} from "context";
import { Tooltip } from "@mui/material";
import bannerMR from "assets/images/identite/banner.png";
import bannerCIV from "assets/images/identite/banner_civ.png";
import { makeStyles } from "@mui/styles";
import PswUpdateComponent from "examples/Navbars/DashboardNavbar/component/PswUpdateComponent";
import { PATH_LOGOUT } from "constant/moduleInfo";
import MDBox from "components/MDBox";
import { CURRENT_PAYS } from "constant";

/* const PswUpdateComponent = lazy(() =>
  import("examples/Navbars/DashboardNavbar/component/PswUpdateComponent")
);
const MDBox = lazy(() => import("components/MDBox")); */

const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundColor: "#2196f3",
    color: "#fff",
    padding: theme.spacing(2),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  bannerImage: {
    maxWidth: "100%",
    height: "auto",
    width: "100%",
  },
}));
function DashboardNavbar({ absolute, light, isMini }) {
  let bannerImg = bannerMR;
  if (CURRENT_PAYS === "Côte D'Ivoire") {
    bannerImg = bannerCIV;
  }
  const navigate = useNavigate();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar /* , openConfigurator */, darkMode } =
    controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openDialogUser, setOpenDialogUser] = useState(false);
  // const route = useLocation().pathname.split("/").slice(1);
  const classes = useStyles();
  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }
    // console.log("ddd", isMini);

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  // const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleUserDialog = () => setOpenDialogUser(!openDialogUser);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });
  const handleLogout = () => {
    // console.log("handleLogout", PATH_LOGOUT);
    navigate(PATH_LOGOUT);
  };
  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      {/* <Suspense fallback={<div>...</div>}> */}
      <MDBox
        variant="gradient"
        bgColor="inherit"
        borderRadius="lg"
        coloredShadow="info"
        mx={2}
        mt={-3}
        p={2}
        mb={10}
        textAlign="center"
        position="relative"
      >
        <img src={bannerImg} className={classes.bannerImage} alt="Beta Click" />
        {/* <img src="assets/images/banner.png" className={classes.bannerImage} alt="Beta Click" /> */}
      </MDBox>
      {/* <ResponsiveBanner /> */}
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        {/* <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox> */}
        {isMini ? null : (
          <MDBox /* sx={{ marginLeft: "auto" }} */>
            {/* <MDBox sx={(theme) => navbarRow(theme, { isMini })}> */}
            {/* <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox> */}
            <MDBox color={light ? "white" : "inherit"}>
              {/* <Link to="/authentication/sign-in/basic">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link> */}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <Tooltip title="Mon Compte" arrow>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleUserDialog}
                >
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Tooltip>
              <Tooltip title="Se Déconnecter" arrow>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleLogout}
                >
                  <Icon sx={iconsStyle}>power_settings_new</Icon>
                </IconButton>
              </Tooltip>
              {openDialogUser && (
                <PswUpdateComponent open={openDialogUser} handleClose={handleUserDialog} />
              )}
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
      {/* </Suspense> */}
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
