import DataTableAction from "components/DataTableRow/dataTableAction";
import DataTableContent from "components/DataTableRow/dataTableContent";
import { useState } from "react";
import {
  SUPER_ADMIN_ROLE,
  // SUPERVISEUR_ROLE,
  // ADMIN_PAYS_ROLE,
  // ADMIN_REGION_ROLE,
  roleFonction,
} from "constant/role";
// import CurrentUserRole from "utils/currentUserRole"; // Import Current User Role
import { tableCellTextFormat } from "utils/textOperation";

export default function useTableUser() {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [actionOption, setActionOption] = useState("");
  // Liste des rôles autorisés à voir les actions
  // const allowedRoles = [SUPER_ADMIN_ROLE, ADMIN_PAYS_ROLE];

  const tableColumns = [
    { Header: "code", accessor: "code", align: "center" },
    { Header: "nom", accessor: "nom", align: "center" },
    { Header: "telephone", accessor: "telephone", align: "center" },
    { Header: "profil", accessor: "profil", align: "center" },
    // { Header: "fonction", accessor: "fonction", align: "center" },
    // { Header: "lieu", accessor: "affectation", align: "center" },
    { Header: "commune", accessor: "commune", align: "center" },
    { Header: "departement", accessor: "departement", align: "center" },
    { Header: "region", accessor: "region", align: "center" },
  ];
  const tableRows = (item, niveau) => {
    let rolesArr = [];
    let fonction = "";
    if (item?.roles?.length > 0) {
      rolesArr = item.roles
        .filter((i) => i.roleName !== SUPER_ADMIN_ROLE)
        .map((x) => {
          const fonctionData = roleFonction.find((y) => y.role === x.roleName);
          // return x.roleName;
          return fonctionData?.fonction ?? x.roleName;
        });
      if (rolesArr.length > 2) {
        fonction = `${rolesArr.slice(0, 2).join(", ")} ...`;
      } else {
        fonction = rolesArr.join(", ");
      }
      // console.log("dddd", rolesArr, roleName);
    } else {
      fonction = "Aucun role";
    }
    let affectation = "";
    /* if (
      rolesArr.some(
        (i) =>
          i === SUPERVISEUR_ROLE || i === ADMIN_PAYS_ROLE
      )
    ) {
      affectation = "Côte D'Ivoire";
    } else if (rolesArr.some((i) => i === ADMIN_REGION_ROLE)) {
      affectation = item?.lieu?.region ?? "";
    } else {
      affectation = item?.lieu?.departement ?? "";
    } */
    // console.log("ddd", niveau);
    if (niveau === "1") {
      affectation = "Côte D'Ivoire";
    } else if (niveau === "2") {
      affectation = item?.lieu?.region ?? "";
    } else if (niveau === "3") {
      affectation = item?.lieu?.departement ?? "";
    } else {
      affectation = item?.lieu?.commune ?? "";
    }
    const userName = `${item?.prenom ?? ""} ${item.nom ?? ""}`;
    const row = {
      code: <DataTableContent title1={tableCellTextFormat(item?.matricul, 15)} />,
      nom: <DataTableContent title1={tableCellTextFormat(userName, 15)} />,
      telephone: <DataTableContent title2={tableCellTextFormat(item?.numTel, 15)} />,
      affectation: <DataTableContent title2={tableCellTextFormat(affectation, 15)} />,
      fonction: <DataTableContent title2={tableCellTextFormat(fonction, 15)} />,
      profil: <DataTableContent title2={tableCellTextFormat(item?.fonction, 15)} />,
      commune: <DataTableContent title2={tableCellTextFormat(item?.lieu?.commune, 15)} />,
      departement: <DataTableContent title2={tableCellTextFormat(item?.lieu?.departement, 15)} />,
      region: <DataTableContent title2={tableCellTextFormat(item?.lieu?.region, 15)} />,
    };
    return row;
  };

  const onSelectedRow = (item, action) => {
    // console.log("onSelectedRow", item, action);
    setSelectedItem(item);
    setActionOption(action);
  };
  // Verification et l'ajout de column action if Current User is allowed the role
  const renderDataTable = (listData, isShowAction, niveau) => {
    /* const currentUserRoles = CurrentUserRole();
    const showActions = allowedRoles.some((role) => currentUserRoles.includes(role));

    if (showActions) {
      const columnsActions = [{ Header: "action", accessor: "action", align: "center" }];
      setColumns([...tableColumns, ...columnsActions]);
    } else {
      setColumns(tableColumns);
    } */
    const columnsActions = [{ Header: "action", accessor: "action", align: "center" }];

    setColumns([...tableColumns, ...columnsActions]);
    if (!isShowAction) {
      setColumns(tableColumns);
    }
    const arrData = listData?.map((item) => {
      const rowData = tableRows(item, niveau);
      const actionComponent = <DataTableAction handleAction={onSelectedRow} item={item} />;

      // Vérification si l'utilisateur a un rôles autorisés
      // const showActions2 = allowedRoles.some((role) => CurrentUserRole().includes(role));
      return {
        ...rowData,
        // action: showActions2 ? actionComponent : null,
        action: actionComponent,
      };
    });

    setRows(arrData);
  };
  return {
    // tableColumnsCampagne,
    // tableRowsCampagne,
    renderDataTable,
    rows,
    columns,
    selectedItem,
    actionOption,
    onSelectedRow,
  };
}
