// LieuAutocomplete.js
import React from "react";
import PropTypes from "prop-types"; // Importation de PropTypes
import { Autocomplete, TextField, Box, Chip } from "@mui/material";

const AutoCompleteComponent = ({
  options,
  name,
  label,
  value,
  onChange,
  isResultObject,
  isMultiSelect,
  onRemove,
  isDisabled,
  onSubmit,
}) => {
  return (
    <Box mb={2} mt={2}>
      <Autocomplete
        disablePortal
        id={`autocomplete-${name}`}
        options={options}
        name={name}
        value={value || ""}
        noOptionsText="Aucune donnée disponible"
        onChange={(event, newValue, reason) => {
          const data = {
            target: {
              name,
              value: {
                newValue,
                reason,
                isResultObject,
                isSelect: true,
              },
            },
          };
          onChange(data);
        }}
        renderTags={(valueTag, getTagProps) =>
          valueTag.map((option, index) => (
            <Chip
              label={option.label}
              {...getTagProps({ index })}
              style={{ backgroundColor: "#4caf50", color: "white" }}
              onDelete={() => onRemove(option)}
            />
          ))
        }
        // renderInput={(params) => <TextField {...params} label={label} />}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                onSubmit();
              }
            }}
          />
        )}
        multiple={isMultiSelect}
        disabled={isDisabled}
      />
    </Box>
  );
};

// Définition des propTypes
AutoCompleteComponent.propTypes = {
  options: PropTypes.array.isRequired, // Tableau d'options pour l'autocomplete
  name: PropTypes.string.isRequired, // Nom de l'input (region, département, commune)
  label: PropTypes.string.isRequired, // Libellé du champ
  value: PropTypes.string.isRequired, // Valeur sélectionnée
  onChange: PropTypes.func.isRequired, // Fonction pour gérer les changements d'input
  isResultObject: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  onRemove: PropTypes.func,
  onSubmit: PropTypes.func,
  isDisabled: PropTypes.bool,
};
AutoCompleteComponent.defaultProps = {
  isResultObject: false,
  isMultiSelect: false,
  onRemove: () => {},
  onSubmit: () => {},
  isDisabled: false,
};
export default AutoCompleteComponent;
