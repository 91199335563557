import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MDInput from "components/MDInput";
import servicesAPI from "services/servicesAPI";
import LoadComponent from "components/LoadComponent";
import {
  SUPER_ADMIN_ROLE,
  // ADMIN_ROLE,
  SUPERVISEUR_ROLE,
  ADMIN_PAYS_ROLE,
  // DPA_ROLE,
  // DSV_ROLE,
  ADMIN_REGION_ROLE,
  ADMIN_DEPARTEMENTAL_ROLE,
  roleFonction,
} from "constant/role";
import userInfos from "utils/userInfos";
import { PATH_DASHBOARD } from "constant/moduleInfo";
import { CURRENT_PAYS } from "constant";

function PswUpdateComponent({ open, handleClose }) {
  const navigate = useNavigate();
  const userData = userInfos();
  const { isAlert, isLoading, message, alertSeverity, postData, handleLoad } = servicesAPI();
  // const [userInfo, setUserInfo] = useState({});
  const [userLieu, setUserLieu] = useState("");
  const [pswCurrent, setPswCurrent] = useState("");
  const [errorPswCurrent, setErrorPswCurrent] = useState("");
  const [pswNew, setPswNew] = useState("");
  // Validation de NewPWW
  const [pswConfirmation, setPswConfirmation] = useState("");
  const [errorPswNew, setErrorPswNew] = useState("");
  const [role, setRole] = useState("");
  const setData = () => {
    try {
      let lieu = "";
      // if (data.roles.find((i) => i.roleName === SUPER_ADMIN_ROLE)) {
      if (
        userData.roles.find(
          (i) =>
            i.roleName === SUPERVISEUR_ROLE ||
            i.roleName === ADMIN_PAYS_ROLE ||
            i.roleName === SUPER_ADMIN_ROLE
        )
      ) {
        lieu = CURRENT_PAYS;
      } else if (userData.roles.find((i) => i.roleName === ADMIN_REGION_ROLE)) {
        lieu = userData.lieu.region;
      } else if (userData.roles.find((i) => i.roleName === ADMIN_DEPARTEMENTAL_ROLE)) {
        lieu = userData.lieu.departement;
      } else {
        lieu = userData.lieu.commune;
      }
      setUserLieu(lieu);
      // console.log("rrrr", userData);
      // const roleUserArray = data.roles.filter((i) => i.roleName !== SUPER_ADMIN_ROLE);
      const roleUserArray = userData.roles;
      if (roleUserArray.length > 0) {
        // const roleUserStr = roleUserArray.map((i) => i.roleName).join(", ");
        const roleUserStr = roleUserArray
          .map((x) => {
            const fonctionData = roleFonction.find((y) => y.role === x.roleName);
            const item = {
              ...x,
              ...{ fonction: fonctionData?.fonction ?? x.roleName },
            };
            return item;
          })
          .map((i) => i.fonction)
          .join(", ");
        setRole(roleUserStr);
      } else {
        setRole("Aucun Role");
      }
    } catch (error) {
      console.log("setData error", error);
    }
  };
  const handleChangePsw = () => {
    try {
      const data = { currentPassword: pswCurrent, newPassword: pswNew };
      postData("Users/password/update", data).then((res) => {
        if (res !== "error") {
          console.log("over ", res);
          handleClose();
          const loadData = {
            isAlert: true,
            message: "Mot de passe modifié avec succès. Veuillez vous reconnecter",
            isLoading: false,
            alertSeverity: "success",
          };
          handleLoad(loadData);
          if (pswCurrent === "123456") {
            setTimeout(() => {
              navigate(PATH_DASHBOARD);
            }, 200);
          } else {
            localStorage.clear(); // Effacement des données de l'utilisateur,
            setTimeout(() => {
              navigate("/"); // Redirection vers la SIGN UP
            }, 200);
          }
        }
      });
    } catch (error) {
      console.log("handleChangePsw error", error);
    }
  };
  const validateForm = () => {
    setErrorPswCurrent("");
    setErrorPswNew("");
    if (pswCurrent === "") {
      setErrorPswCurrent("Veuillez saisir votre mot de passe actuel");
    } else if (pswNew === "") {
      setErrorPswNew("Veuillez saisir un nouveau mot de passe");
    } else if (pswNew !== pswConfirmation) {
      setErrorPswNew(`Les deux mots de passe ne sont pas identiques`);
    } else if (pswNew === "123456") {
      setErrorPswNew(`le mot de passe ${pswNew} n'est pas accepté`);
    } else {
      handleChangePsw();
    }
  };
  useEffect(() => {
    setData();
  }, []);
  // console.log("rrrr", userData);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Informations du Compte</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {/* <Typography gutterBottom>{userInfo.nom ?? "No data"}</Typography>
          <Typography gutterBottom>{}</Typography> */}
          <Box mb={2}>
            <MDInput
              type="text"
              label="Prenom & Nom"
              value={`${userData.preNom} ${userData.nom}`}
              fullWidth
              disabled
            />
          </Box>
          <Box mb={2}>
            <MDInput
              type="text"
              label="Numéro de Téléphone"
              value={userData.numTel}
              fullWidth
              disabled
            />
          </Box>
          <Box mb={2}>
            <MDInput type="text" label="Lieu d'Affectation" value={userLieu} fullWidth disabled />
          </Box>
          <Box mb={2}>
            <MDInput
              type="text"
              label="Roles"
              /* value={
                userInfo.roles
                  ? userInfo.roles.map((i) => i.roleName).join(", ") ?? "Aucun Role"
                  : "Aucun Role"
              } */
              value={role}
              fullWidth
              disabled
            />
          </Box>
          {userData?.userTags && userData?.userTags.length > 0 && (
            <Box mb={2}>
              <MDInput
                type="text"
                label="Projet"
                value={userData?.userTags[0]?.tag?.name}
                fullWidth
                disabled
              />
            </Box>
          )}
          <Box mb={2}>
            <MDInput
              type="text"
              label="Profil"
              /* value={
                userInfo.roles
                  ? userInfo.roles.map((i) => i.roleName).join(", ") ?? "Aucun Role"
                  : "Aucun Role"
              } */
              value={userData.function}
              fullWidth
              disabled
            />
          </Box>
          <Typography display="block" variant="button" fontWeight="medium">
            Modifier le mot de passe
          </Typography>
          <Box mb={2} mt={2}>
            <MDInput
              type="password"
              label="Mot de passe Actuel"
              value={pswCurrent}
              fullWidth
              onChange={(event) => {
                setPswCurrent(event.target.value);
              }}
              error={!!errorPswCurrent}
              helperText={errorPswCurrent && errorPswCurrent}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  validateForm();
                }
              }}
            />
          </Box>
          <Box mb={2}>
            <MDInput
              type="password"
              label="Nouveau Mot de passe"
              value={pswNew}
              fullWidth
              onChange={(event) => {
                setPswNew(event.target.value);
              }}
              error={!!errorPswNew}
              helperText={errorPswNew && errorPswNew}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  validateForm();
                }
              }}
            />
          </Box>

          <Box mb={2}>
            <MDInput
              type="password"
              label="Confirmation du mot de passe"
              value={pswConfirmation}
              fullWidth
              onChange={(event) => {
                setPswConfirmation(event.target.value);
              }}
              error={!!errorPswNew}
              helperText={errorPswNew && errorPswNew}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  validateForm();
                }
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={validateForm}>
            Modifier
          </Button>
        </DialogActions>
      </Dialog>
      <LoadComponent loadData={{ isAlert, isLoading, message, alertSeverity, handleLoad }} />
    </div>
  );
}

export default PswUpdateComponent;
PswUpdateComponent.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
PswUpdateComponent.defaultProps = {
  open: false,
  // eslint-disable-next-line prettier/prettier
  handleClose: () => { },
};
