import listProfil, {
  listProfilN1,
  listProfilN2,
  listProfilN3,
  listProfilN4I,
  listProfilN4R,
  listProfilN5,
} from "constant/staticData/listProfil";

const fetchCustomProfil = (niveau) => {
  let customProfil = listProfil;
  if (niveau === "1") {
    customProfil = listProfilN1;
  } else if (niveau === "2") {
    customProfil = listProfilN2;
  } else if (niveau === "3") {
    customProfil = listProfilN3;
  } else if (niveau === "4I" || niveau === "4INS") {
    customProfil = listProfilN4I;
  } else if (niveau === "4R") {
    customProfil = listProfilN4R;
  } else if (niveau === "5E" || niveau === "5P") {
    customProfil = listProfilN5;
  }

  return customProfil;
};
export default fetchCustomProfil;
